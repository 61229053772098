

module.exports = function loaderja(locale, cb) {
  require.ensure([
      'moment/locale/ja'
      , 'sugar-date/locales/ja'
    ], (require) => {
    const moment = require('moment');
      require('moment/locale/ja');
      try { moment.locale(locale); } catch(e) { moment.locale(locale.split('-')[0].toLowerCase()); }

    const Sugar = require('sugar-date');
      require('sugar-date/locales/ja');
      try { Sugar.Date.setLocale(locale); } catch(e) { try { Sugar.Date.setLocale(locale.split('-')[0].toLowerCase()); } catch(ee) { } }

    cb();
  }, 'locale-ja');
};

