import type React from 'react';

import { notification } from '../components/Notification';

type Milliseconds = number;

export const NOTIFY_DURATION_SHORT = 3000 as Milliseconds;
export const NOTIFY_DURATION_DEFAULT = 5000 as Milliseconds; // the library default

/**
 * If you want the toast to stay open until the user manually dismissed it, pass
 * `false` to `autoCloseMs`. `Infinity` or `undefined` don't work.
 */
export function notify({
  severity,
  message,
  description,
  autoCloseMs,
  toastId,
}: {
  severity: 'error' | 'info' | 'success';
  message?: string;
  description?: React.ReactNode;
  autoCloseMs?: Milliseconds | false;
  toastId: string;
}) {
  const autoClose = autoCloseMs ?? NOTIFY_DURATION_DEFAULT;

  switch (severity) {
    case 'error': {
      notification.error(
        { message: message ?? 'Error', description: description },
        {
          autoClose: autoClose,
          toastId: toastId,
        }
      );
      break;
    }

    case 'info': {
      notification.info(
        {
          message: message ?? 'Info',
          description: description,
        },
        {
          autoClose: autoClose,
          toastId: toastId,
        }
      );
      break;
    }

    case 'success': {
      notification.success(
        {
          message: message ?? 'Success',
          description: description,
        },
        { autoClose: autoClose, toastId: toastId }
      );
      break;
    }
  }
}
