// cSpell:ignore COMMITHASH
type HostingMode = 'cloud' | 'self-host';

interface Axiom {
  authLocal: boolean;
  mode: HostingMode;
  version: string;

  // Dash only variables that are used in shared `axiom` components which means they should all be marked "optional"
  // Ideally these shouldn't be read in `axiom` but too much of a headache to fix.
  authEmail?: boolean;
  playMode?: boolean;
  playModeReadonlyToken?: string;
}

const axiomBase: Axiom = (window as any).axiom;

// Adjust version so it includes the frontend commit hash.
axiomBase.version = `${axiomBase.version}-${
  process.env.COMMITHASH === 'development' ? 'dev' : process.env.COMMITHASH?.substring(0, 10)
}`;

// dont log in tests
if (!process.env.TEST) {
  console.info('Axiom App Version', axiomBase.version);
}

interface AxiomDash extends Axiom {
  apiUrl: string;
  publicApiUrl: string;
  enableJSMonitoring: boolean;
  needsInit: boolean;
  orgs: unknown[]; // Best to reference `orgStore` version of `orgs` because it's properly typed.
  stripePubKey: string;
  workers: { label: string; url: string }[];
  features: Record<string, boolean>; // Not currently used but keeping around for reference
  launchDarklyClientId: string;
  mixpanelToken: string;
  mixpanelRecordSessionsPercent: string;
}

export const axiom = axiomBase as AxiomDash;
