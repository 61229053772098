import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '../../util/styles';

import { type RadixClassNameTypeHelper } from './temporaryRadixTypeHelper';

const tooltipAnimationClasses = 'animate-in fade-in-0 data-[state=closed]:animate-out data-[state=closed]:fade-out-0';

const TooltipVariants = cva(
  cn(
    'max-h-[calc(var(--radix-tooltip-content-available-height)_-_8px) rounded text-sm shadow',
    tooltipAnimationClasses
  ),
  {
    variants: {
      variant: {
        highContrast: 'bg-[--gray-12] text-[--gray-01]',
        lowContrast: 'border bg-elevated text-base',
      },
      noPadding: {
        true: 'p-0',
        false: 'px-3 py-1.5',
      },
    },
    defaultVariants: {
      variant: 'highContrast',
      noPadding: false,
    },
  }
);
type TooltipVariantsProps = VariantProps<typeof TooltipVariants>;

const TooltipArrowVariants = cva(cn('shadow', tooltipAnimationClasses), {
  variants: {
    variant: {
      highContrast: 'fill-gray-12',
      lowContrast: 'fill-[var(--background-elevated)] stroke-gray-06',
    },
  },
  defaultVariants: {
    variant: 'highContrast',
  },
});

const TooltipProvider = TooltipPrimitive.Provider;

const TooltipRoot = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> &
    RadixClassNameTypeHelper & { arrowClassName?: string } & TooltipVariantsProps
>(
  (
    {
      className,
      arrowClassName,
      collisionPadding = 8,
      sideOffset = 4,
      align = 'start',
      children,
      variant,
      noPadding,
      ...props
    },
    ref
  ) => (
    <TooltipPrimitive.TooltipPortal>
      <TooltipPrimitive.Content
        ref={ref}
        sideOffset={sideOffset}
        className={cn(TooltipVariants({ variant: variant, noPadding: noPadding }), className, 'z-[9999]')}
        align={align}
        collisionPadding={collisionPadding}
        {...props}
      >
        {children}
        <TooltipPrimitive.Arrow className={cn(TooltipArrowVariants({ variant: variant }), arrowClassName)} />
      </TooltipPrimitive.Content>
    </TooltipPrimitive.TooltipPortal>
  )
);
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

/**
 * caveats:
 * - "area" is focusable, but it's annoying to test and we don't have maps in axiom.
 * - "a" is only focusable if it has a href.
 */
const FOCUSABLE_ELEMENTS = ['button', 'a', 'input', 'select', 'textarea'];

/**
 * Simplified version of tooltip, API matches the one in tippy.
 * For more control, use TooltipRoot, TooltipTrigger and TooltipContent.
 */
const Tooltip = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Trigger> &
    RadixClassNameTypeHelper & {
      __debug__open?: boolean;
      delayDuration?: React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Root>['delayDuration'];
      onOpenChange?: React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Root>['onOpenChange'];
      tooltipContent: React.ReactNode;
      contentProps?: React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> &
        RadixClassNameTypeHelper & { arrowClassName?: string; __debug__open?: boolean };
    } & TooltipVariantsProps
>(
  (
    {
      __debug__open,
      children,
      className,
      tooltipContent,
      contentProps: tooltipContentProps,
      variant,
      noPadding,
      delayDuration,
      ...props
    },
    ref
  ) => {
    const childIsElement = React.isValidElement(children);
    const childIsFocusableElement =
      childIsElement &&
      ((children.props.tabIndex !== undefined && children.props.tabIndex >= 0) ||
        (typeof children.type == 'string' && FOCUSABLE_ELEMENTS.includes(children.type)));

    return (
      <TooltipRoot delayDuration={delayDuration ?? 100} onOpenChange={props.onOpenChange} open={__debug__open}>
        <TooltipTrigger
          className={cn('select-text', className)}
          type="button"
          ref={ref}
          asChild={childIsElement && childIsFocusableElement}
          {...props}
        >
          {children}
        </TooltipTrigger>
        <TooltipContent
          align={tooltipContentProps?.align ?? 'center'}
          variant={variant}
          noPadding={noPadding}
          {...tooltipContentProps}
        >
          {tooltipContent}
        </TooltipContent>
      </TooltipRoot>
    );
  }
);
Tooltip.displayName = 'Tooltip';

export const TooltipMaybe = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Trigger> &
    RadixClassNameTypeHelper & {
      delayDuration?: React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Root>['delayDuration'];
      tooltipContent: React.ReactNode;
      condition: boolean;
      contentProps?: React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> &
        RadixClassNameTypeHelper & { arrowClassName?: string };
    } & TooltipVariantsProps
>(({ condition, children, tooltipContent, contentProps: tooltipContentProps, noPadding, variant, ...props }, ref) => {
  if (!condition) {
    return children;
  }

  const childIsElement = React.isValidElement(children);
  const childIsFocusableElement =
    childIsElement &&
    ((children.props.tabIndex !== undefined && children.props.tabIndex >= 0) ||
      (typeof children.type == 'string' && FOCUSABLE_ELEMENTS.includes(children.type)));

  return (
    <TooltipRoot delayDuration={props.delayDuration ?? 300}>
      <TooltipTrigger type="button" ref={ref} asChild={childIsElement && childIsFocusableElement} {...props}>
        {children}
      </TooltipTrigger>
      <TooltipContent
        align={tooltipContentProps?.align ?? 'center'}
        variant={variant}
        noPadding={noPadding}
        {...tooltipContentProps}
      >
        {tooltipContent}
      </TooltipContent>
    </TooltipRoot>
  );
});
TooltipMaybe.displayName = 'TooltipMaybe';

export { Tooltip, TooltipRoot, TooltipTrigger, TooltipContent, TooltipProvider };
