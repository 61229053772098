import React from 'react';
import { type SubmitHandler, type FieldValues, type UseFormReturn } from 'react-hook-form';

import { cn } from '../../util/styles';
import { Form } from '../primitives/Form';

import styles from '../BasicForm.module.scss';

export type BasicFormButtonSize = 'default' | 'small';

export type BasicFormProps<FormType extends FieldValues = FieldValues> = Omit<
  React.FormHTMLAttributes<HTMLFormElement>,
  'children' | 'onSubmit'
> & {
  buttons?: React.ReactNode;
  buttonSize?: BasicFormButtonSize;
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
  form: UseFormReturn<FormType>;
  modal?: boolean;
  noPadding?: boolean;
  onSubmit: SubmitHandler<FormType>;
  buttonsClassName?: string;
};

/**
 * Note that unlike shadcn/ui, we here need to pass `form` as a specific prop instead of spreading it onto the component
 */
export function BasicForm<FormType extends FieldValues>(props: BasicFormProps<FormType>) {
  const {
    buttons,
    buttonSize,
    children,
    className,
    contentClassName,
    form,
    modal,
    noPadding,
    onSubmit,
    buttonsClassName,
    ...passthrough
  } = props;

  return (
    <Form {...form}>
      <form
        className={cn(
          styles.form,

          // @ts-expect-error @todo: fix this
          styles[`buttons-${buttonSize}`],
          { [styles.modalMode]: modal },
          className
        )}
        onSubmit={form.handleSubmit(onSubmit)}
        {...passthrough}
      >
        <div className={cn(styles.content, { ['!p-0']: noPadding }, contentClassName)}>{children}</div>
        {buttons ? <div className={cn(styles.buttons, buttonsClassName)}>{buttons}</div> : null}
      </form>
    </Form>
  );
}
