// cSpell:ignore codegen

import { getBaseUrl } from '../../util/axios';

import { DashApi } from './codegen/Dash';
import { ReposApi } from './v2/codegen/Repos';

export * from './types';

export const dashApi = new DashApi();

export const reposApi = new ReposApi({
  baseURL: getBaseUrl('/v2'),
});
