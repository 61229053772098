

module.exports = function loaderes(locale, cb) {
  require.ensure([
      'moment/locale/es-do', 'moment/locale/es-us', 'moment/locale/es'
      , 'sugar-date/locales/es'
    ], (require) => {
    const moment = require('moment');
      require('moment/locale/es-do');
      require('moment/locale/es-us');
      require('moment/locale/es');
      try { moment.locale(locale); } catch(e) { moment.locale(locale.split('-')[0].toLowerCase()); }

    const Sugar = require('sugar-date');
      require('sugar-date/locales/es');
      try { Sugar.Date.setLocale(locale); } catch(e) { try { Sugar.Date.setLocale(locale.split('-')[0].toLowerCase()); } catch(ee) { } }

    cb();
  }, 'locale-es');
};

