/**
 * Note that feature flags, in their current implementation, are not very secure.
 * A bad actor could figure out what feature flags exist and turn them on for
 * themselves without using the UI.
 *
 * If it would be bad if a user with bad intentions were to access a feature, then
 * that feature should not be controlled by a feature flag as currently implemented.
 *
 * HOW TO USE:
 * - Add any new feature flags to `featureFlags`
 * - each org in `config` gets either `all` or an array of feature flags
 *
 * FUTURE: allow non boolean feature flags
 */

export const featureFlags = [
  { name: 'annotations', id: 'annotations' },
  { name: 'asana mode (queries +20s)', id: 'asana-mode' },
  { name: 'trace-notification', id: 'trace-notification' },
  { name: 'show-env-tag', id: 'show-env-tag' },
] as const satisfies FeatureFlag[];

const AXIOMERS_DEV = 'axiomers-ft83';
const AXIOMERS_STAGING = 'axiomers-6qt8';
const AXIOMERS_PROD = 'axiomers-exxp';

export const featureFlagConfig: FlagPermissions = {
  [AXIOMERS_DEV]: 'can-toggle-all',
  [AXIOMERS_STAGING]: 'can-toggle-all',
  [AXIOMERS_PROD]: 'can-toggle-all',
};

/**
 * Types
 */

export type FeatureFlagId = (typeof featureFlags)[number]['id'];
export type FeatureFlag = { name: string; id: string };
type OrgName = string; // e.g. axiomers-ft83
type AllPermissions = 'can-toggle-all';
type SomePermissions = { id: FeatureFlagId; canToggle: boolean }[];
type OrgPermissions = AllPermissions | SomePermissions;
type FlagPermissions = Record<OrgName, OrgPermissions>;
