import React from 'react';
import { type FieldError } from 'react-hook-form';

import { cn } from '../../util/styles';
import { type RadixClassNameTypeHelper } from '../primitives/temporaryRadixTypeHelper';

export const FormItemHelper = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement> &
    RadixClassNameTypeHelper & {
      helperText: React.ReactNode;
      hasError?: boolean; // manually add error for situations where we can't rely on react-hook-form
      error?: FieldError | string;
      formMessageId?: string;
    }
>(({ className, hasError, helperText, formMessageId, error, ...passthrough }, ref) => {
  const text = error ? (typeof error === 'string' ? error : String(error?.message)) : helperText ?? '';

  return (
    <div
      className={cn(
        'self-center overflow-hidden align-middle transition-all duration-150 animate-in fade-in',
        text ? 'h-auto' : 'h-0'
      )}
    >
      <p
        ref={ref}
        id={formMessageId}
        data-testid="formItemMessage"
        className={cn(
          'text-subtle mb-0 text-sm leading-6 [&_p:last-child]:mb-0',
          (error || hasError) && 'text-red',
          className
        )}
        {...passthrough}
      >
        {text ?? helperText ?? ''}
      </p>
    </div>
  );
});

FormItemHelper.displayName = 'FormItemHelper';
