

module.exports = function loaderen(locale, cb) {
  require.ensure([
      'moment/locale/en-au', 'moment/locale/en-ca', 'moment/locale/en-gb', 'moment/locale/en-ie', 'moment/locale/en-il', 'moment/locale/en-nz'
      
    ], (require) => {
    const moment = require('moment');
      require('moment/locale/en-au');
      require('moment/locale/en-ca');
      require('moment/locale/en-gb');
      require('moment/locale/en-ie');
      require('moment/locale/en-il');
      require('moment/locale/en-nz');
      try { moment.locale(locale); } catch(e) { moment.locale(locale.split('-')[0].toLowerCase()); }

    
    cb();
  }, 'locale-en');
};

