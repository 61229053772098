/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Repo, ShareRequest, ShareResponse, TokenInfo } from "./data-contracts";
import { ContentType, HttpClientV2, RequestParams } from "./http-client";

export class ReposApi<SecurityDataType = unknown> extends HttpClientV2<SecurityDataType> {
  /**
   * @description Retrieve a list of all repositories.
   *
   * @tags Repos
   * @name GetRepos
   * @summary Get all repos
   * @request GET:/repos
   * @secure
   * @response `200` `(Repo)[]` A list of repos
   * @response `400` `void` Invalid request
   */
  getRepos = (params: RequestParams = {}) =>
    this.request<Repo[], void>({
      path: `/repos`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Endpoint to create a new object of a specific type.
   *
   * @tags Repos
   * @name PostRepos
   * @summary Create a new object
   * @request POST:/repos
   * @secure
   * @response `201` `Repo` Repo created successfully
   * @response `400` `void` Invalid input
   */
  postRepos = (body: Repo, params: RequestParams = {}) =>
    this.request<Repo, void>({
      path: `/repos`,
      method: "POST",
      body: body,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Get all shared repos
   *
   * @tags Repos
   * @name GetSharedRepos
   * @summary Get all shared repos
   * @request GET:/repos/share
   * @secure
   * @response `200` `Record<string,(string)[]>` List of shared repos grouped by id
   */
  getSharedRepos = (params: RequestParams = {}) =>
    this.request<Record<string, string[]>, any>({
      path: `/repos/share`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get all shared repo tokens
   *
   * @tags Repos
   * @name GetSharedRepoTokens
   * @summary Get all shared repo tokens
   * @request GET:/repos/share/tokens
   * @secure
   * @response `200` `(ShareResponse)[]` List of shared repo tokens retrieved successfully
   */
  getSharedRepoTokens = (params: RequestParams = {}) =>
    this.request<ShareResponse[], any>({
      path: `/repos/share/tokens`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Retrieve a specific repository by its unique identifier.
   *
   * @tags Repos
   * @name GetRepoById
   * @summary Get a single repo by ID
   * @request GET:/repos/{id}
   * @secure
   * @response `200` `Repo` A single repo object
   * @response `404` `void` Repo not found
   */
  getRepoById = (id: string, params: RequestParams = {}) =>
    this.request<Repo, void>({
      path: `/repos/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Update the details of a repository by its unique identifier.
   *
   * @tags Repos
   * @name UpdateRepo
   * @summary Update a repo
   * @request PUT:/repos/{id}
   * @secure
   * @response `200` `Repo` Repo updated successfully
   * @response `400` `void` Invalid input
   * @response `404` `void` Repo not found
   */
  updateRepo = (id: string, body: Repo, params: RequestParams = {}) =>
    this.request<Repo, void>({
      path: `/repos/${id}`,
      method: "PUT",
      body: body,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Delete a repository by its unique identifier.
   *
   * @tags Repos
   * @name DeleteRepo
   * @summary Delete a repo
   * @request DELETE:/repos/{id}
   * @secure
   * @response `204` `void` Repo deleted successfully
   * @response `404` `void` Repo not found
   */
  deleteRepo = (id: string, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/repos/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Shares a repository resource and returns a shareable link. Optionally accepts a list of organization IDs to restrict access.
   *
   * @tags Repos
   * @name ShareRepo
   * @summary Share a repo and get a shareable link
   * @request POST:/repos/{id}/share
   * @secure
   * @response `200` `ShareResponse` Shareable link created successfully
   * @response `400` `void` Invalid input
   * @response `404` `void` Repo not found
   */
  shareRepo = (id: string, body: ShareRequest, params: RequestParams = {}) =>
    this.request<ShareResponse, void>({
      path: `/repos/${id}/share`,
      method: "POST",
      body: body,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Unshares a repo with the org.
   *
   * @tags Repos
   * @name UnShareRepo
   * @summary Delete the shared reference of a repo
   * @request DELETE:/repos/{id}/share
   * @secure
   * @response `200` `void` Repo unshared successfully
   * @response `400` `void` Invalid input
   * @response `404` `void` Repo not found
   */
  unShareRepo = (
    id: string,
    body: {
      orgId?: string;
      type?: "PACKAGE" | "DATASET" | "MONITOR" | "DASHBOARD" | "VIRTUAL_FIELD" | "REGEX" | "QUERY" | "VIEW";
    },
    params: RequestParams = {},
  ) =>
    this.request<void, void>({
      path: `/repos/${id}/share`,
      method: "DELETE",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Expire a shared repository using its shareable link.
   *
   * @tags Repos
   * @name ExpireSharedRepo
   * @summary Expire a shared repo via shareable link
   * @request POST:/repos/{token}/expire
   * @secure
   * @response `200` `void` Successfully expired the shared repo
   * @response `404` `void` Shared repo not found or access expired
   */
  expireSharedRepo = (token: string, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/repos/${token}/expire`,
      method: "POST",
      secure: true,
      ...params,
    });
  /**
   * @description Access the entire data structure of the resource that has been shared with this token.
   *
   * @tags Repos
   * @name TokenInfo
   * @summary Access token resource info
   * @request GET:/repos/{token}/info
   * @secure
   * @response `200` `TokenInfo` Successfully retrieved the token info
   * @response `404` `void` Token not found
   */
  tokenInfo = (token: string, params: RequestParams = {}) =>
    this.request<TokenInfo, void>({
      path: `/repos/${token}/info`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Retrieve a shared repository using its shareable link.
   *
   * @tags Repos
   * @name UseShareLink
   * @summary Access a shared repo via shareable link
   * @request POST:/s/{token}
   * @secure
   * @response `200` `Repo` Successfully retrieved the shared repo
   * @response `404` `void` Shared repo not found or access expired
   */
  useShareLink = (token: string, params: RequestParams = {}) =>
    this.request<Repo, void>({
      path: `/s/${token}`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Delete a shared token so it can no longer be used
   *
   * @tags Repos
   * @name DeleteShareLink
   * @summary Delete shared token
   * @request DELETE:/s/{token}
   * @secure
   * @response `200` `void` Successfully retrieved the shared repo
   * @response `404` `void` Shared repo not found or access expired
   */
  deleteShareLink = (token: string, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/s/${token}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}
