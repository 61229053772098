// ******************************************************
// Originally taken from Ant Design:
// https://github.com/ant-design/ant-design/blob/3.26.12/components/modal/Modal.tsx
// ******************************************************
import Dialog from 'rc-dialog';
import React from 'react';

import { cn } from '../util/styles';

import { Button, type ButtonType, type NativeButtonProps } from './Button';
import { Icon } from './Icon';

let mousePosition: { x: number; y: number } | undefined;

export const MODAL_WIDTH = 428;

// ref: https://github.com/ant-design/ant-design/issues/15795
const getClickPosition = (e: MouseEvent) => {
  mousePosition = {
    x: e.pageX,
    y: e.pageY,
  };
  // If a click event occurs within 100ms , the animation will be displayed from the click position.
  // Otherwise, it will be displayed directly by zoom.
  // This is compatible with non-click expansion.
  setTimeout(() => (mousePosition = undefined), 100);
};

// Only click events support animation expansion from the mouse position.
if (window?.document?.documentElement) {
  document.documentElement.addEventListener('click', getClickPosition);
}

export interface ModalBaseProps {
  afterClose?: () => void; // FIXME: it doesn't look like this is called
  bodyStyle?: React.CSSProperties;
  cancelButtonProps?: NativeButtonProps;
  cancelText?: React.ReactNode;
  centered?: boolean;
  children?: React.ReactNode;
  className?: string;
  closable?: boolean;
  closeIcon?: React.ReactNode;
  destroyOnClose?: boolean;
  footer?: React.ReactNode;
  forceRender?: boolean;
  getContainer?: string | HTMLElement | getContainerFunc | false;
  keyboard?: boolean;
  mask?: boolean;
  maskClosable?: boolean;
  maskStyle?: React.CSSProperties;
  maskTransitionName?: string;
  okButtonProps?: NativeButtonProps;
  okLoading?: boolean;
  okText?: React.ReactNode;
  okType?: ButtonType;
  /** Click the upper right corner of the modal box, cancel button, Props.maskClosable value when the mask layer or keyboard is pressed when Esc is pressed Callback */
  onCancel?: (e: React.MouseEvent<HTMLElement> | React.SyntheticEvent<HTMLDivElement>) => void;
  onOk?: (e: React.MouseEvent<HTMLElement>) => void;
  prefixCls?: string;
  style?: React.CSSProperties;
  title?: React.ReactNode;
  transitionName?: string;
  visible?: boolean;
  width?: number;
  wrapClassName?: string;
  wrapProps?: any;
  zIndex?: number;
}

type getContainerFunc = () => HTMLElement;

/**
 * @deprecated Depends on rc-* component
 */
export const ModalBase_DEPRECATED = ({
  footer,
  visible = false,
  wrapClassName,
  centered,
  getContainer,
  closeIcon,
  onCancel,
  onOk,
  okButtonProps,
  cancelButtonProps,
  cancelText,
  okType = 'primary' as ButtonType,
  okLoading = false,
  okText,
  width = 520,
  transitionName = 'zoom',
  maskTransitionName = 'fade',
  ...restProps
}: ModalBaseProps) => {
  const prefixCls = 'axiom-modal'; // THIS NAME MUST BE KEPT IN SYNC WITH dash/styles/base/modal.scss

  const renderFooter = () => {
    return (
      <div>
        <Button onClick={onCancel} type="axi-secondary" {...cancelButtonProps}>
          {cancelText || 'Cancel'}
        </Button>
        <Button type={okType || 'axi-primary'} loading={okLoading} onClick={onOk} {...okButtonProps}>
          {okText || 'OK'}
        </Button>
      </div>
    );
  };

  const closeIconToRender = (
    <span className={`${prefixCls}-close-x`}>
      {closeIcon || <Icon name="times" aria-label="icon: close" size="2x" />}
    </span>
  );

  // Hack to deal with Ant Design's animations.
  // For some reason modals briefly flash visible after they've animated out.
  if (!visible) {
    return null;
  }

  return (
    <Dialog
      {...restProps}
      getContainer={getContainer}
      prefixCls={prefixCls}
      wrapClassName={cn({ [`${prefixCls}-centered`]: !!centered }, wrapClassName)}
      footer={footer === undefined ? renderFooter() : footer}
      visible={visible}
      mousePosition={mousePosition}
      onClose={onCancel}
      closeIcon={closeIconToRender}
      width={width}
      transitionName={transitionName}
      maskTransitionName={maskTransitionName}
    />
  );
};
