import { action, observable, makeObservable, type IReactionDisposer, reaction, runInAction } from 'mobx';
import { createContext } from 'react';

import { checkFeatureFlag, ldClient } from '../util/featureFlags/useFeatureFlags';
import { safeLocalStorage } from '../util/safeLocalStorage';

import { StoreBase } from './StoreBase';

const LOCAL_STORAGE_KEY = 'userPreferences';

// TODO: @cje - move this into postgres?
type UserPreferences = {
  showSidebarNullValues?: boolean;
  queryMode?: 'batched' | 'progressive';
  updatedDatasetsPageY25W09?: boolean;
};

export class UserPreferencesStore extends StoreBase {
  @observable
  public showSidebarNullValues = false;

  @observable
  public queryMode?: 'batched' | 'progressive';

  @observable
  private progressiveQueriesFlag = false;

  @observable
  private updatedDatasetsPageY25W09Flag = false;

  @observable
  public updatedDatasetsPageY25W09?: boolean;

  private progressiveQueriesFlagDisposer: IReactionDisposer;
  private updatedDatasetsPageFlagDisposer: IReactionDisposer;

  constructor() {
    super();

    makeObservable(this);

    // @ts-expect-error
    window.axiom.toggleQueryMode = () => {
      if (this.queryMode === 'progressive') {
        this.updateQueryMode('batched');
      } else {
        this.updateQueryMode('progressive');
      }
    };
    // @ts-expect-error
    window.axiom.toggleUpdatedDatasetsPageY25W09 = () => {
      this.updateUpdatedDatasetsPageY25W09(!this.updatedDatasetsPageY25W09);
    };

    // Load preferences from localStorage first
    this.loadPreferences();

    this.progressiveQueriesFlag = ldClient.variation('progressive-queries', false);
    this.updatedDatasetsPageY25W09Flag = ldClient.variation('fe-updated-datasets-page-y-25-w-09', false);

    ldClient.on('change:progressive-queries', (value) => {
      runInAction(() => {
        this.progressiveQueriesFlag = value;
      });
    });

    ldClient.on('change:fe-updated-datasets-page-y-25-w-09', (value) => {
      runInAction(() => {
        this.updatedDatasetsPageY25W09Flag = value;
      });
    });

    this.progressiveQueriesFlagDisposer = reaction(
      () => this.progressiveQueriesFlag,
      (enabled: boolean) => {
        if (enabled) {
          this.loadProgressiveBasedOnFlag();
        } else {
          this.removeProgressiveFromSettings();
        }
      }
    );

    this.updatedDatasetsPageFlagDisposer = reaction(
      () => this.updatedDatasetsPageY25W09Flag,
      (enabled: boolean) => {
        if (enabled) {
          this.loadUpdatedDatasetsPageBasedOnFlag();
        } else {
          this.removeUpdatedDatasetsPageFromSettings();
        }
      }
    );
  }

  public dispose() {
    this.progressiveQueriesFlagDisposer?.();
    this.updatedDatasetsPageFlagDisposer?.();
  }

  @action.bound
  private loadPreferences() {
    try {
      const userPreferences = JSON.parse(safeLocalStorage.getItem(LOCAL_STORAGE_KEY)!) as UserPreferences;

      if (userPreferences) {
        this.showSidebarNullValues = userPreferences.showSidebarNullValues ?? false;
        this.queryMode = userPreferences.queryMode;
        this.updatedDatasetsPageY25W09 = userPreferences.updatedDatasetsPageY25W09;
      }
    } catch (error) {
      console.warn('Failed to load user preferences settings from localStorage.');
    }
  }

  @action.bound
  private loadProgressiveBasedOnFlag() {
    if (this.progressiveQueriesFlag && this.queryMode === undefined) {
      this.updateQueryMode('progressive');
    }
  }

  @action.bound
  private removeProgressiveFromSettings() {
    try {
      const userPreferences = JSON.parse(safeLocalStorage.getItem(LOCAL_STORAGE_KEY)!) as UserPreferences;
      if (userPreferences) {
        delete userPreferences.queryMode;

        safeLocalStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ ...userPreferences }));
      }
    } catch (error) {
      console.warn('Failed to load user preferences settings from localStorage.');
    }
  }

  @action.bound
  private loadUpdatedDatasetsPageBasedOnFlag() {
    // Only set the preference if the feature is enabled AND the user hasn't set a preference yet
    if (this.updatedDatasetsPageY25W09Flag && this.updatedDatasetsPageY25W09 === undefined) {
      this.updateUpdatedDatasetsPageY25W09(true);
    }
  }

  @action.bound
  private removeUpdatedDatasetsPageFromSettings() {
    try {
      const userPreferences = JSON.parse(safeLocalStorage.getItem(LOCAL_STORAGE_KEY)!) as UserPreferences;
      if (userPreferences) {
        delete userPreferences.updatedDatasetsPageY25W09;
        // Reset the property to undefined (not set) when the feature is disabled
        this.updatedDatasetsPageY25W09 = undefined;
        safeLocalStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ ...userPreferences }));
      }
    } catch (error) {
      console.warn('Failed to load user preferences settings from localStorage.');
    }
  }

  @action.bound
  public updateShowSidebarNullValues(showSidebarNullValues: boolean): void {
    this.showSidebarNullValues = showSidebarNullValues;
    this.saveUserPreferences();
  }

  @action.bound
  public updateQueryMode(queryMode: 'batched' | 'progressive'): void {
    const progressiveQueriesEnabledLD = checkFeatureFlag('progressive-queries');

    if (progressiveQueriesEnabledLD) {
      this.queryMode = queryMode;
      this.saveUserPreferences();
    }
  }

  @action.bound
  public updateUpdatedDatasetsPageY25W09(enabled: boolean): void {
    const updatedDatasetsPageEnabled = checkFeatureFlag('fe-updated-datasets-page-y-25-w-09');

    if (updatedDatasetsPageEnabled) {
      this.updatedDatasetsPageY25W09 = enabled;
      this.saveUserPreferences();
    }
  }

  private saveUserPreferences() {
    const progressiveQueriesEnabledLD = checkFeatureFlag('progressive-queries');
    const updatedDatasetsPageLD = checkFeatureFlag('fe-updated-datasets-page-y-25-w-09');

    safeLocalStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify({
        showSidebarNullValues: this.showSidebarNullValues,
        ...(progressiveQueriesEnabledLD ? { queryMode: this.queryMode } : {}),
        ...(updatedDatasetsPageLD ? { updatedDatasetsPageY25W09: this.updatedDatasetsPageY25W09 } : {}),
      })
    );
  }
}

export const UserPreferencesStoreContext = createContext<UserPreferencesStore | null>(null);
UserPreferencesStoreContext.displayName = 'UserPreferencesStoreContext';
