import { z } from 'zod';

import {
  type Org as ApiOrg,
  type Storage as ApiStorage,
  type License as ApiLicense,
  type UserDetailsRole,
  type Membership,
  type Monitor as ApiMonitor,
  type MonitorHistory as ApiMonitorHistory,
  type QueryRequestWithOptions,
  type APLRequestWithOptions,
} from '../shared/dash';

export const OwnerRole = 'owner';
export const AdminRole = 'admin';
export const UserRole = 'user';
export const ReadOnlyRole = 'read-only';
export const NoneRole = 'none';
export const Roles = [OwnerRole, AdminRole, UserRole, ReadOnlyRole, NoneRole] as const;
export const AxiomOwnerRole = 'axiom-owner';
export const AxiomAdminRole = 'axiom-admin';
export const AxiomUserRole = 'axiom-user';
export const AxiomReadOnlyRole = 'axiom-read-only';
export const AxiomNoneRole = 'axiom-none';
export const AxiomRoles = [AxiomOwnerRole, AxiomAdminRole, AxiomUserRole, AxiomReadOnlyRole, AxiomNoneRole];

interface AxiomFeatures {
  rbac?: boolean;
  datasetsSharing?: boolean;
  endpointsDatadog?: boolean;
  endpointsHoneycomb?: boolean;
  endpointsLoki?: boolean;
  endpointsSplunkevents?: boolean;
  endpointsSecureSyslog?: boolean;
  notifiersEmail?: boolean;
  notifiersDiscord?: boolean;
  notifiersDiscordWebhook?: boolean;
  notifiersMicrosoftTeams?: boolean;
  notifiersOpsgenie?: boolean;
  notifiersPagerduty?: boolean;
  notifiersSlack?: boolean;
  netlifyIntegration?: boolean;
  cloudflareIntegration?: boolean;
  notifiersWebhook?: boolean;
  traces?: boolean;
  tabularFormat?: boolean;
  assistant?: boolean;
  datadogMigrationAssistant?: boolean;
  libraries?: boolean;
}

export type LicenseTier = ApiLicense['tier'];

export type PaymentStatusType = Org['paymentStatus'];

export interface License extends Omit<ApiLicense, 'features'> {
  features: AxiomFeatures;
  tier: LicenseTier;
}

export interface Org extends Omit<ApiOrg, 'role' | 'license'> {
  role: string;
  license: License;
  plan: LicenseTier;
}

export const RoleCapabilityActionSchema = z.enum(['*', 'create', 'read', 'update', 'delete']);
export const RoleCapabilityAccessSchema = z.enum(['allow', 'deny']);

export const CapabilitySchema = z.record(
  z.object({
    Actions: z.array(RoleCapabilityActionSchema),
    Access: RoleCapabilityAccessSchema,
  })
);

export type RoleCapabilityAction = z.infer<typeof RoleCapabilityActionSchema>;
export type RoleCapabilityAccess = z.infer<typeof RoleCapabilityAccessSchema>;
export type Capability = z.infer<typeof CapabilitySchema>;

// User is used when returning multiple users and includes the role and permissions.
export type User = {
  id: string;
  email: string;
  name: string;
  role: UserDetailsRole;
  dataset_permissions?: any;
  org_permissions?: Capability;
  permissions: string[];
  role_membership?: Membership[];
  group_membership?: Membership[];
  defaultDashboards?: Record<string, string>;
};

export interface Storage extends ApiStorage {
  id?: string;
  name?: string;
  enabled?: boolean;
}

export interface Monitor extends Omit<ApiMonitor, 'id' | 'type' | 'disabledUntil' | 'query'> {
  id: string;
  disabledUntil?: Date;
  query?: QueryRequestWithOptions | APLRequestWithOptions;
  datasetId?: string;
  type?: ApiMonitor['type'];
  // Only used in frontend.
  isNew?: boolean;
}

export interface MonitorHistory extends Omit<ApiMonitorHistory, 'data'> {
  data?: Record<string, any[][]>;
}

export type MonitorHistoryState = Record<string, HistoryStateEvent[]>;

export type HistoryStateEvent = {
  notified: boolean;
  alert_states: ('open' | 'closed')[];
  matching_values: any[];
  group_values: any[];
  time: string;
  run_time: string;
  startTime: string;
  endTime: string;
  notification_error?: string;
  notification_failed?: boolean;
  status: 'open' | 'closed';
  tooltip?: string;
  disabled?: boolean;
  notifier?: any[];
  error?: boolean;
};

export type MonitorGroupStates = 'normal' | 'triggered' | 'disabled' | 'errored';
export type MonitorStatusGroup = {
  normal: string[];
  triggered: string[];
  disabled: string[];
  errored: string[];
};

export const VALID_SHARE_TYPES = [
  'package',
  'dataset',
  'monitor',
  'dashboard',
  'virtual_field',
  'regex',
  'query',
  'view',
] as const;

export type ValidShareTypes = (typeof VALID_SHARE_TYPES)[number];
