

module.exports = function loaderru(locale, cb) {
  require.ensure([
      'moment/locale/ru'
      , 'sugar-date/locales/ru'
    ], (require) => {
    const moment = require('moment');
      require('moment/locale/ru');
      try { moment.locale(locale); } catch(e) { moment.locale(locale.split('-')[0].toLowerCase()); }

    const Sugar = require('sugar-date');
      require('sugar-date/locales/ru');
      try { Sugar.Date.setLocale(locale); } catch(e) { try { Sugar.Date.setLocale(locale.split('-')[0].toLowerCase()); } catch(ee) { } }

    cb();
  }, 'locale-ru');
};

