

module.exports = function loaderhu(locale, cb) {
  require.ensure([
      'moment/locale/hu'
      
    ], (require) => {
    const moment = require('moment');
      require('moment/locale/hu');
      try { moment.locale(locale); } catch(e) { moment.locale(locale.split('-')[0].toLowerCase()); }

    
    cb();
  }, 'locale-hu');
};

