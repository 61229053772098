

module.exports = function loaderzh(locale, cb) {
  require.ensure([
      'moment/locale/zh-cn', 'moment/locale/zh-hk', 'moment/locale/zh-tw'
      , 'sugar-date/locales/zh-CN', 'sugar-date/locales/zh-TW'
    ], (require) => {
    const moment = require('moment');
      require('moment/locale/zh-cn');
      require('moment/locale/zh-hk');
      require('moment/locale/zh-tw');
      try { moment.locale(locale); } catch(e) { moment.locale(locale.split('-')[0].toLowerCase()); }

    const Sugar = require('sugar-date');
      require('sugar-date/locales/zh-CN');
      require('sugar-date/locales/zh-TW');
      try { Sugar.Date.setLocale(locale); } catch(e) { try { Sugar.Date.setLocale(locale.split('-')[0].toLowerCase()); } catch(ee) { } }

    cb();
  }, 'locale-zh');
};

