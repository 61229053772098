import { type AxiosResponse } from 'axios';
import React, { Fragment } from 'react';

import { CopyText } from '../../components/CopyText';
import { Link } from '../../components/Link';
import { notification } from '../../components/Notification';
import { axiomTraceUrl } from '../axiomTraceUrl';
import { getEnv } from '../env';
import { featureFlagStore } from '../featureFlags/FeatureFlagStore';

const TRACE_HEADER_NAME = 'x-axiom-trace-id';

const PAGES_TO_NOTIFY = ['/query'];

const isNotifyPage = () => {
  const { pathname } = window.location;

  return PAGES_TO_NOTIFY.some((page) => pathname.endsWith(page));
};

export const notifyTrace = (response: AxiosResponse, error?: boolean) => {
  if (!featureFlagStore.hasFeatureFlag('trace-notification')) {
    return;
  }

  const { method, url = '' } = response.config || {};

  if (!isNotifyPage() && !error) {
    return;
  }

  const traceId = response.headers?.[TRACE_HEADER_NAME];
  if (traceId) {
    const env = getEnv();
    const linkToTrace = env && axiomTraceUrl({ env: env, traceId: traceId });

    const path = url?.split('?')[0];
    const config = {
      message: 'View trace',
      description: (
        <Fragment>
          {`${method} ${path}`}
          <br />
          {linkToTrace ? (
            <Link external href={linkToTrace}>
              {traceId}
            </Link>
          ) : (
            <CopyText text={traceId} />
          )}
        </Fragment>
      ),
    };

    if (error) {
      notification.error(config);
    } else {
      notification.info(config);
    }
  }
};
